import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Musical Dance - Παιδικό Τμήμα";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το μιούζικαλ είναι ένας συνδυασμός τεχνών που περιλαμβάνει υποκριτική,
          τραγούδι και χορό.
        </P>
        <P>
          Μέσα από το συγκεκριμένο μάθημα του χορού, αφηγούμαστε κάθε φορά μια
          ιστορία και εκφράζουμε τα συναισθήματα που μας δημιουργεί.
        </P>
        <P>
          Αυτό βοηθά τα παιδιά να αποκτήσουν δεξιότητες, πέρα από τη ρυθμική
          κίνηση, όπως είναι η έκφραση του σώματος, η συναισθηματική ευχέρεια
          και η ανεπτυγμένη φαντασία.
        </P>
      </Section.Main>
    </Layout>
  );
}
